import { mapActions, mapGetters, mapState } from 'vuex'
import BigNumber from 'bignumber.js'

import BaseField from '@/components/lib/BaseField/BaseField.vue'

export default {
  components: {
    BaseField
  },
  props: {
  },
  data () {
    return {
      searchWord: '',
      expandedGroup: []
    }
  },
  computed: {
    ...mapState({
      currencies: state => state.currencies.list.value,
      markets: state => state.coins.markets
    }),
    ...mapGetters({
      getMarketBySymbol: 'fees/getMarketBySymbol',
      getCurrency: 'currencies/getCurrency',
      currenciesIsLoaded: 'currencies/isLoaded'
    }),
    feesItems () {
      return this.currencies.filter(e => !!e.active).map(currency => {
        const result = {
          name: currency.name,
          symbol: currency.symbol,
          icon: currency.icon,
          background: currency.background,
          commission: '0'
        }
        const plugins = currency.withdrawPlugins
          .filter(plugin => plugin.type === 'CryptoWithdrawPlugin')
          .filter(e => e.commission && e.commission.value)

        if (plugins.length > 1) {
          const commissionAmounts = plugins
            .map(e => this.removeDecimals(e.commission.value, currency).toNumber())

          const min = Math.min(...commissionAmounts)
          const max = Math.max(...commissionAmounts)

          result.commission = `${min} - ${max}`
        } else if (plugins.length === 1) {
          result.commission = this.removeDecimals(plugins[0].commission.value, currency)
        }

        return result
      })
    },
    filteredFeesItems () {
      if (this.searchWord) {
        return this.feesItems.filter((item) => {
          return item.name.toLowerCase().includes(this.searchWord.toLowerCase())
        })
      } else {
        return this.feesItems
      }
    },
    currenciesLoad () {
      return this.currencies
    }
  },
  methods: {
    ...mapActions({
      loadCurrencies: 'currencies/loadCurrencies'
    }),
    isExpanded (key) {
      return this.expandedGroup.indexOf(key) !== -1
    },
    toggleExpansion (key) {
      if (this.isExpanded(key)) {
        this.expandedGroup.splice(this.expandedGroup.indexOf(key), 1)
      } else {
        this.expandedGroup.push(key)
      }
    },
    removeDecimals (value, currency) {
      return value == null
        ? null
        : new BigNumber(value).div(new BigNumber(10).pow(currency.tradeDecimals))
    }
  },
  async serverPrefetch () {
    await this.loadCurrencies()
  },
  async mounted () {
    if (!this.currenciesIsLoaded) {
      await this.loadCurrencies()
    }
  }
}
